@import 'styles/variables.scss';

.normal {
  display: flex; align-items: center;
}

.current {
  margin-right: 25px; position: relative;
  pointer-events: auto;

  &Button {
    color: #435cc8; font-size: 15px; font-family: $r; font-weight: 500;
    display: flex; align-items: center; height: 44px; padding: 0 18px;
    background: #fff; border: solid 1px #435cc8; border-radius: 5px;
    cursor: pointer;
  }

  &Icon {
    font-size: 18px;
    position: relative; top: -2px;
    pointer-events: none;
  }

  &Date {
    margin-left: 15px;
    pointer-events: none;
  }
}

.list {
  display: flex; align-items: center;
  border: solid 1px #dadada; border-radius: 5px;
  overflow: hidden;
}

.item {
  border-right: solid 1px #dadada;
  pointer-events: auto;

  &:last-child {
    border-right: none;
  }
}
