@import 'styles/variables.scss';

.normal {
  height: 100%; width: 100%; padding-bottom: 200px; display: flex; flex-direction: column; align-items: center; justify-content: center;
  background: url('/assets/img/background.png') center bottom no-repeat;
}

.title {
  color: #393952; font-size: 130px; font-family: $r; font-weight: 700;
  margin-bottom: 30px;
}

.text,
.link {
  color: #393952; font-size: 20px; font-family: $r; font-weight: 500;

  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
