@import 'styles/variables.scss';

.normal {
  color: #3a3952; font-size: 15px; font-family: $r; line-height: 16px; font-weight: 400; white-space: nowrap;
  position: relative;
  border-left: solid 1px #ebebed; border-bottom: solid 1px #ebebed;

  &:nth-child(2) {
    border-left: none;
  }
  &:last-child {
     border-right: solid 1px #ebebed;
  }
}

.button {
  color: inherit; font-size: inherit; font-family: inherit; line-height: inherit; text-align: left;
  height: 100%; width: 100%; padding: 5px 10px;
  background: none;
}


.dimension {
  color: #cccccc; font-size: 10px; font-family: $r;
}
