@import 'styles/variables.scss';

@keyframes cogRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.normal {
  display: flex; justify-content: flex-end; position: relative; padding: 0 0 20px;
}

.opened {
  composes: normal;
}

.open {
  color: #3a3952; font-size: 14px; font-family: $r; font-weight: 400;
  display: flex; align-items: center; position: relative; z-index: 15;
  background: none;
  cursor: pointer;

  &Icon {
    margin-right: 10px;
    pointer-events: none;

    .opened & {
      animation: cogRotate 0.4s ease 1;
    }
  }

  &Text {
    color: #bdbdc3;
    position: relative;
    pointer-events: none;

    .open:hover & {
      color: #3a3952;
    }

    &:after {
      content: '';
      display: block; width: 100%; height: 1px; position: absolute; left: 0; bottom: 0;
      background: #3a3952;
    }
  }
}

.close {
  color: #ed1e26; font-size: 16px;
  position: absolute; left: 15px; top: 15px; display: flex; align-items: center; justify-content: center; width: 30px; height: 30px;
  background: none;
  cursor: pointer;

  &Icon {
    font-weight: 700;
  }
}

.columns {
  display: block; padding: 60px 25px 25px 60px; position: absolute; right: -25px; top: -30px; z-index: 14;
  background: #fff; box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1);
  opacity: 0; transition: top 0.15s ease, opacity 0.15s ease;
  pointer-events: none;

  .opened & {
    top: -20px;
    opacity: 1;
    pointer-events: auto;
  }
}
