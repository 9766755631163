@import 'styles/variables.scss';

.normal {
  display: flex; align-items: stretch; height: 100%;
}

.left {
  width: 50%;
  background: url('/assets/img/background.png') center bottom no-repeat;
}

.right {
  width: 50%; display: flex; flex-direction: column; align-items: center;
  background: #fff;
}

.auth {
  &Footer {
    width: 100%; display: flex; align-items: center; justify-content: space-between; height: 50px; padding: 0 30px;
    background: #f6f6f6;

    &Link {
      color: #3a3952; font-family: $r; font-size: 16px; font-weight: 400;
      opacity: 0.9;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.links {
  display: flex;

  &Item {
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.copyright {
  color: #3a3952; font-family: $r; font-size: 16px; font-weight: 400;
  opacity: 0.9;
}
