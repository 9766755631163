@import 'styles/variables.scss';

.normal {
  display: flex; align-items: center; flex-wrap: wrap; padding: 0 10px 12px; margin: -7px 0;
}

.open {
  color: #3a3952; font-size: 24px;
  position: relative; left: -10px; height: 42px; width: 77px; padding-right: 14px; display: flex; align-items: center; justify-content: flex-end;
  background: #fff; border-radius: 0 4px 4px 0;
}

.filter {
  color: #fff;
  margin: 7px; height: 42px; min-width: 200px; padding: 0 20px 0 25px; display: flex; align-items: center; justify-content: space-between;
  background: #414159; border: solid 2px #414159; border-radius: 50px;
  cursor: pointer;

  &:hover {
    color: #414159;
    background: #fff;
  }

  &Title {
    font-size: 14px; font-family: $r; font-weight: 400;
  }

  &Icon {
    margin-left: 15px;
  }
}
