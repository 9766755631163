@import 'styles/variables.scss';

.normal {
  margin-right: 25px; display: flex; align-items: stretch; position: relative;
  background: #fff; border: solid 1px #dadada; border-radius: 5px;
  cursor: default;
  pointer-events: auto;

  &:Last-child {
    margin-right: 0;
  }
}

.green {
  composes: normal;
  border-color: #07bf6f;
}

.red {
  composes: normal;
  border-color: #ec4e20;
}

.title {
  color: #484848; font-size: 16px; font-family: $r; font-weight: 400; white-space: nowrap;
  padding: 0 25px 0 15px; display: flex; align-items: center;
  cursor: default;
}


.inputs {
  min-width: 80px; display: flex; align-items: baseline; padding: 0 15px;
  background: #ebebed;

  .green & {
    background: #07bf6f;
  }

  .red & {
    background: #ec4e20;
  }
}

.input {
  color: #6d6d6d; font-size: 16px; font-family: $r; font-weight: 500; text-align: center;
  width: 50px; min-width: 50px; height: 42px; margin-right: 3px;
  background: none;

  .green &, .red & {
    color: #fff;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.dimension {
  color: #b6b6b6; font-size: 11px; font-family: $r; font-weight: 500;

  .green &, .red & {color: #fff;}
}
