.normal {
  padding: 50px; display: flex; align-items: flex-start; justify-content: space-between; min-height: 100%;
  background: #eaeaf2;
}

.filters, .labels {
  display: flex; align-items: flex-start; flex-wrap: wrap; margin: -15px;
}

.filters {
  margin-right: 30px;
}

.labels {
  flex-shrink: 0;
}
