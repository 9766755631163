@import 'styles/variables.scss';

.normal {
  display: flex; align-items: center; margin-right: 15px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}

.active {
  composes: normal;
}

.input {
  display: none;
}

.icon {
  color: #878793; font-size: 20px;
  margin-right: 10px;

  .active & {
    color: #357ffa;
  }
}

.label {
  color: #3a3952; font-size: 16px; font-family: $r; font-weight: 400;
  display: flex; align-items: center;
  opacity: 0.42;

  .active & {
    opacity: 1;
  }

  &Icon {
    font-size: 14px;
    margin-right: 5px;
  }
}

.green {
  color: #07bf6f;
  opacity: 1;
}

.red {
  color: #ec4e20;
  opacity: 1;
}
