@import 'styles/variables.scss';

.normal {
  min-width: 310px; padding: 25px 0; display: flex; align-items: center;
  border-bottom: solid 1px #ebebed;

  &:last-child {
    border-bottom: none;
  }
}

.blue {
  composes: normal;
  border-bottom: solid 1px #2d6cd5;
}

.delete {
  flex-shrink: 0; margin-right: 15px;
  background: none;
  cursor: pointer;
}

.title {
  color: #3a3952; font-size: 16px; font-family: $r; font-weight: 400;
  width: 100%; padding-right: 15px;

  .blue & {
    color: #fff;
  }
}

.info {
  flex-shrink: 0; display: flex; align-items: center;

  &Item {
    color: #727272; font-size: 14px; font-family: $r; font-weight: 400;
    display: flex; align-items: center; margin-right: 30px;

    .blue & {
      color: #fff;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.icon {
  &Delete {
    color: #ed1e26; font-size: 16px; font-weight: 700;

    .blue & {
      color: #fff;
    }
  }

  &Info {
    color: #727272; font-size: 18px;
    margin-right: 10px; position: relative; top: -2px;

    .blue & {
      color: #fff;
    }
  }
}
