@import 'styles/variables.scss';

.normal {
  color: #3a3952; font-size: 15px; font-family: $r; font-weight: 400; text-align: center;
  background: #fff !important; border-left: solid 1px #ebebed;
}

.dimension {
  color: #cccccc; font-size: 10px; font-family: $r; font-weight: 400;
  margin-left: 2px;
}

.icon {
  font-size: 15px;
  margin-right: 7px; position: relative; top: 1px;
}

.green {
  composes: icon;
  color: #07bf6f;
}

.red {
  composes: icon;
  color: #ec4e20;
}
