.normal {
  min-height: 100%; display: flex; align-items: stretch;
  background: #eaeaf2;
}

.table {
  flex-shrink: 0;
}

.cards {
  position: relative; width: 100%;
  background-color: rgb(244, 244, 244); box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1);
}

.resize {
  color: #3b3a53; font-size: 40px;
  display: flex; align-items: center; position: absolute; left: -48px; top: 50%; transform:  translate(0, -50%);
  background: none;
  cursor: grab; opacity: 0.7;

  &:active {
    cursor: grabbing;
  }
}
