@import 'styles/variables.scss';

.normal {
  margin: 15px;
  background: #fff; border-radius: 5px; box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.blue {
  composes: normal;
  background: #357ffa;
}

.title {
  color: #3a3952; font-size: 18px; font-family: $r; font-weight: 500; line-height: 22px;
  padding: 20px;
  border-bottom: solid 1px #ebebed;

  .blue & {
    color: #fff;
    border-bottom: solid 1px #2d6cd5;
  }
}

.list {
  padding: 0 20px;
}

.input {

}

.save {
  cursor: pointer;
}

.add {
  color: #727272; font-size: 24px;
  width: 100%; height: 32px; display: flex; align-items: center; justify-content: center;
  background: #fafafa; border-top: solid 1px #ebebed;
  cursor: pointer;

  .blue & {
    color: #fff;
    background: #2d6cd5; border-top-color: #2d6cd5;
  }

  &:hover {
    color: #fff;
    background: #435cc8;
  }
}
