@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.normal {
  display: flex; flex-wrap: wrap; margin-bottom: 12px;

  @include respond-to(1279px) {
    justify-content: space-between;
  }
}

.title {
  color: #3a3952; font-size: 20px; font-family: $r; font-weight: 500;
  width: 100%; margin-bottom: 10px;
}

.desc {
  color: #3a3952; font-size: 16px; font-family: $r; font-weight: 400; line-height: 20px;
  display: flex; align-items: center; margin-right: 325px;

  @include respond-to(1499px) {
    margin-right: 150px;
  }
  @include respond-to(1279px) {
    margin-right: 15px;
  }

  &Icon {
    font-size: 22px;
    margin-right: 6px;
  }

  &Text {
    opacity: 0.8;
  }

  &Button {
    color: #3a3952; font-size: 16px; font-family: $r; font-weight: 400; line-height: 20px;
    display: flex; align-items: center;
    background: none;
    cursor: pointer;

    &:hover {
      color: #435cc8;
    }

    &Icon {
      margin-right: 7px;
    }
  }
}

.form {
  display: flex; align-items: center; padding: 0 16px; position: relative;

  &:before {
    content: '';
    display: block; width: 100%; height: 44px; position: absolute; left: 0; top: 50%; transform: translateY(-50%);
    border: solid 1px #dadada; border-radius: 50px;
    pointer-events: none;
  }

  &Button {
    color: #878793; font-size: 20px;
    margin-right: 20px; display: flex;
    background: none;
    cursor: pointer;
  }

  &Input {
    color: #3a3952; font-size: 16px; font-family: $r; font-weight: 400;
    width: 405px;

    &::-webkit-placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder,
    &:-moz-placeholder {
      color: #878793;
    }
  }
}
