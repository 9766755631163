@import 'styles/variables.scss';

.normal {
  flex-shrink: 0; position: absolute; top: 100%; left: 0; width: 100%;
  background: #f0f0f7;
}

.rows {
  position: relative; z-index: 3;
  box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1);
}
