@import 'styles/variables.scss';

.normal {
  display: flex; flex-direction: column; margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.header {
  margin-bottom: 30px;
}

.title, .subtitle {
  color: #3a3952; font-family: $r;
}

.title {
  font-size: 30px; font-weight: 500;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.subtitle {
  font-size: 16px; font-weight: 400;
  opacity: 0.8;
}

.form {
  display: flex; flex-direction: column;
}

.button {
  color: #fff; font-size: 16px; font-family: $r; font-weight: 500;
  align-self: center; width: 100%; height: 50px; display: flex; align-items: center; justify-content: center;
  background: #357ffa; border: solid 2px #357ffa; border-radius: 5px;
  transition: color 0.2s ease, background 0.2s ease;
  cursor: pointer;

  &:hover {
    color: #357ffa;
    background: #fff;
  }
}

.disabled {
  composes: button;
  opacity: 0.7;
  pointer-events: none;
}
