@import 'styles/variables.scss';

.normal {
  width: 450px; display: flex; align-items: center; flex-direction: column; position: absolute; right: 0; top: 100%; z-index: 4; padding-top: 30px;
  background: #fff; box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1);
}

.ava {
  display: block; width: 80px; height: 80px; position: relative; margin-bottom: 23px;

  &Figure {
    width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;
    background: #f0f0f7; border-radius: 50%;
    overflow: hidden;
  }

  &Img {

  }

  &Edit {
    position: absolute; right: -6px; top: 1px; width: 28px; height: 28px; display: flex; align-items: center; justify-content: center;
    background: #fff; border-radius: 50px;
    overflow: hidden; cursor: pointer;

    &:hover {
      .avaIcon {
        opacity: 1;
      }
    }
  }

  &Icon {
    color: #3a3952; font-size: 20px;
    opacity: 0.6;
  }
}

.name {
  display: flex; align-items: center; margin-bottom: 11px;

  &User {
    color: #3a3952; font-size: 24px; font-family: $r; font-weight: 500;
  }

  &Edit {
    color: #3a3952; font-size: 20px;
    margin-left: 10px; display: flex;
    background: none; border-bottom: solid 3px #3a3952;
    opacity: 0.42; cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &Icon {

  }
}

.email {
  color: #525252; font-size: 16px; font-family: $r; font-weight: 400;
  margin-bottom: 40px;
}

.pass {
  display: flex; align-items: center; padding-left: 30px; position: relative; margin-bottom: 30px;
  background: none;
  cursor: pointer;

  &:hover {
    .passText {
      opacity: 0.7;
    }
  }

  &:after {
    content: '';
    display: block; width: calc(100% - 30px); height: 1px; position: absolute; right: 0; bottom: 0;
    background: #3a3952;
    opacity: 0.85;
  }

  &Icon {
    color: #414159; font-size: 24px;
    position: absolute; left: 0; top: 0;
  }

  &Text {
    color: #3a3952; font-size: 16px; font-family: $r; font-weight: 400;
    display: inline-block; position: relative;
    opacity: 0.42;
  }
}

.stats {
  width: 100%; padding: 25px 0; display: flex; align-items: center;
  background: #fafafa; border-top: solid 1px #ebebed;

  &Item {
    width: 100%; display: flex; flex-direction: column; align-items: center;
    border-right: #ebebed solid 1px;

    &:last-child {
      border-right: none;
    }
  }

  &Value {
    color: #3a3952; font-size: 18px; font-family: $r; font-weight: 700;
    margin-bottom: 12px;
  }

  &Label {
    color: #3a3952; font-size: 15px; font-family: $r; font-weight: 400;
    opacity: 0.9;
  }
}
