@import 'styles/variables.scss';

.form {
  &Module {
    &Inputs {
      display: flex; align-items: flex-start;
    }
    &Header {
      color: #3a3952; font-family: $r; font-size: 16px; font-weight: 500;
      margin-bottom: 15px; display: flex; justify-content: space-between;
    }
  }
}

.agreement {
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}
