@import 'styles/variables.scss';

.normal {

}

.cell {
  color: #3a3952; font-size: 11px; font-family: $r; font-weight: 500; line-height: 16px; text-transform: uppercase; white-space: nowrap;
  background: #f8f8f8; border-top: solid 1px #ebebed; border-right: solid 1px #ebebed;
}

.firstCell {
  composes: cell;
  position: sticky; left: 0; z-index: 5;
  background: #fff; border-top: none;
}

.button {
  color: inherit; font-size: inherit; font-family: inherit; line-height: inherit; text-align: left;
  padding: 5px 10px; width: 100%; height: 100%;
  background: none;
  cursor: pointer;
}
