@import 'styles/variables.scss';

@keyframes showAnimation {
  from {
    top: -20px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.normal {
  height: 100%; display: flex; flex-direction: column; justify-content: center; width: 430px; position: relative;
  overflow-y: auto;
  opacity: 0; top: -20px;
  animation: showAnimation 0.2s ease 1; animation-fill-mode: forwards;
}

.textSpan,
.change {
  font-size: 16px; font-family: $r; font-weight: 500;
}

.text {
  display: flex; justify-content: center;

  &Span {
    color: #3a3952;
    margin-right: 20px;
    opacity: 0.9;

    &:last-child {
      margin-right: 0;
    }
  }
}

.change {
  color: #357ffa; text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
