.normal {
  flex-shrink: 0; min-width: 230px; height: 100%; padding: 10px 20px; display: flex; align-items: center; justify-content: flex-end;
  border-left: solid 1px #e4e4e4;
}

.notification {
  display: flex; align-items: center; margin-right: 50px;

  &:last-child {
    margin-right: 0;
  }

  &Item {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &Button {
    color: #3a3952; font-size: 18px;
    position: relative;
    background: transparent;
    cursor: pointer;

    &:hover {
      .icon {
        opacity: 1;
      }
    }

    &:after {
      content: none;
      display: block; width: 12px; height: 12px; position: absolute; right: -4px; top: -3px;
      background: #ec4e20; border: solid 2px #fff; border-radius: 10px;
    }

    &New {
      composes: notificationButton;

      &:after {
        content: '';
      }
    }
  }
}

.icon {
  opacity: 0.6;
}

.user {
  display: flex; align-items: center; justify-content: center; width: 50px; height: 50px;
  background: #f0f0f7;border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
}

.avatar {

}
