@import 'styles/variables.scss';

.normal {
  color: #fff; font-size: 11px; font-family: $r; font-weight: 500; line-height: 16px; white-space: nowrap; text-align: center;
  position: sticky; top: 0; z-index: 4; padding: 5px 10px; vertical-align: middle;
  border-top: solid 1px #ebebed; border-left: solid 1px #ebebed; border-bottom: solid 1px #ebebed;

  &:last-child {
     border-right: solid 1px #ebebed;
  }
}

.green {
  composes: normal;
  background: #07bf6f;
}

.red {
  composes: normal;
  background: #ec4e20;
}

.icon {
  font-size: 14px;
  margin-right: 7px; position: relative; top: 1px;
}
