@import 'styles/variables.scss';

.normal {
  background: #fff;

  &:nth-child(even) {
    background: #f8f8f8;

    td {
      background: #f8f8f8;
    }
  }
  &:last-child {
    td {
      border-bottom: solid 1px #ebebed;
    }
  }

  td {
    background: #fff;
  }
}
