@import 'styles/variables.scss';

.li {
  margin-bottom: 4px; display: flex; align-items: center; padding: 3px;
  background: #fff;
}

.title {
  color: #3a3952; font-size: 16px; font-family: $r; font-weight: 400;
  margin-right: 10px;
}

.icon {
  font-size: 20px;
  margin-right: 4px; position: relative; top: -1px;
}
