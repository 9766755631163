@import 'styles/variables.scss';

.normal {
  height: 100%; display: flex; align-items: flex-end; justify-content: center; padding-bottom: 55px;
  background: url('/assets/img/background.png') center bottom no-repeat;
  overflow-y: auto;
}

.advice {
  width: calc(100% - 30px); height: 60px; max-width: 1580px; position: relative; display: flex; align-items: center;
  background: #fff; box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &:after {
    content: '';
    display: block; position: absolute; width: 122px; height: 100%; right: 0; top: 0; z-index: 10;
    background-image: linear-gradient( 86deg, rgba(255,255,255,0.50196) 0%, rgba(255,255,255,0.75) 10%, rgb(255,255,255) 100%);
  }

  &Text {
    color: #3a3952; font-size: 15px; font-family: $r; font-weight: 400; white-space: nowrap;
    position: relative; z-index: 8;
  }
}

.label {
  position: relative; flex-shrink: 0; margin-right: 30px; display: flex; align-items: center; height: 100%; padding: 0 25px;

  &:before {
    content: '';
    display: block; width: 330px; height: 330px; position: absolute; left: 0; top: 0; transform: translate(-50%, -50%) rotate(-60deg); z-index: 2;
    background: #435cc8;
  }
  &:after {
    content: '';
    display: block; width: 338px; height: 338px; position: absolute; left: 0; top: 0; transform: translate(-50%, -50%) rotate(-60deg); z-index: 1;
    background: #6c6cb9;
  }

  &Icon {
    color: #fff; font-size: 28px;
    margin-right: 11px; position: relative; z-index: 3; top: -1px;
  }

  &Text {
    color: #fff; font-size: 14px; font-family: $r; font-weight: 500; line-height: 18px; text-transform: uppercase;
    position: relative; z-index: 3; top: 1px;
  }
}
