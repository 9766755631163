@import 'styles/variables.scss';

.scrollable {
  width: 100%; height: 100%; overflow: auto;
}

.table {
  width: 100%;
  border-collapse: separate;
}
