@import 'styles/variables.scss';

.normal {
  display: flex; justify-content: space-between; align-items: center; height: 72px; flex-shrink: 0; padding: 0 50px;
  background: #fff; border-top: solid 1px #e8e8e8; border-bottom: solid 1px #e8e8e8;
}

.copy {
  width: 15%; flex-shrink: 0;
}

.links {
  width: 100%; display: flex; height: 100%;

  &Left, &Right {
    display: flex; width: 50%; height: 100%;
  }

  &Left {
    justify-content: flex-end; padding-right: 70px;
  }

  &Right {
    // padding-left: 70px;
    justify-content: center; width: 100%;
  }
}

.payment {
  width: 15%; flex-shrink: 0; display: flex; justify-content: flex-end;

  &List {
    display: flex; align-items: center;
  }

  &Item {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &Img {

  }
}
