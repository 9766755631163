@import 'styles/variables.scss';

.normal {

}

.form {
  &Module {
    &Header {
      color: #3a3952; font-family: $r; font-size: 16px; font-weight: 500;
      margin-bottom: 15px; display: flex; justify-content: space-between;
    }
  }
}

.forgotPass {
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}
