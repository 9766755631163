@import 'styles/variables.scss';

@keyframes refreshRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.normal {
  position: relative; padding-top: 12px; z-index: 10;
}

.opened {
  composes: normal;
}

.dropdown {
  padding: 20px 25px 65px 17px; position: absolute; left: 0; bottom: -30px; left: -25px;
  opacity: 0; transition: bottom 0.15s ease, opacity 0.15s ease;
  pointer-events: none;

  &:after {
    content: '';
    display: block; width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 10;
    background: #fff; box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1); border: solid 1px #ebebed;
    pointer-events: none;
  }

  .opened & {
    bottom: -20px;
    opacity: 1;
    pointer-events: auto;
  }
}

.button {
  display: flex; position: relative; z-index: 11;
  background: none;
  cursor: pointer;

  &Icon {
    font-size: 16px;
    margin-right: 12px;
    pointer-events: none;

    .opened & {
      animation: refreshRotate 0.4s ease 1;
    }
  }

  &Label {
    color: #bdbdc3; font-size: 14px; font-family: $r; font-weight: 400;
    position: relative;
    pointer-events: none;

    .button:hover & {
      color: #3a3952;
    }

    &:after {
      content: '';
      display: block; width: 100%; height: 1px; position: absolute; left: 0; bottom: 0;
      background: #3a3952;
    }
  }
}

.close {
  color: #ed1e26; font-size: 16px;
  position: absolute; right: 10px; bottom: 15px; display: flex; align-items: center; justify-content: center; width: 30px; height: 30px; z-index: 12;
  background: none;
  cursor: pointer;

  &Icon {
    font-weight: 700;
  }
}

.list {
  display: flex; flex-direction: column; align-items: flex-start; margin-bottom: -5px; position: relative; z-index: 13;
}
