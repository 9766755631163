@import 'styles/variables.scss';

.normal {
  position: relative;
}

.header {
  display: flex; align-items: center; height: 80px; position: relative; z-index: 20;
  background: #fff; box-shadow: 0 -3px 3px 5px rgba(0,0,0,0.1);
}

.title {
  color: #393952; font-size: 22px; font-family: $r; font-weight: 700;
  width: 100%; display: flex; align-items: flex-end; justify-content: center;

  &Span {
    position: relative; top: 4px;
  }
}

.logo {
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}
