html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

audio, canvas, img, video {
  vertical-align: middle; }

h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0; }

body {
  line-height: 1;
  background: #fff; }
  body.on-load {
    overflow: hidden; }
  body * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  body *:before, body *:after {
    box-sizing: border-box; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }
  a:hover, a:focus, a:active {
    cursor: pointer; }

form, input, select, textarea, button, a, fieldset {
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  outline: none;
  border: 0; }

textarea {
  resize: vertical; }

@font-face {
  font-family: "roboto";
  src: url("/assets/fonts/roboto-light/roboto-light.eot");
  src: url("/assets/fonts/roboto-light/roboto-light.eot") format("embedded-opentype"), url("/assets/fonts/roboto-light/roboto-light.woff2") format("woff2"), url("/assets/fonts/roboto-light/roboto-light.woff") format("woff"), url("/assets/fonts/roboto-light/roboto-light.ttf") format("truetype"), url("/assets/fonts/roboto-light/roboto-light.svg#roboto-light") format("svg");
  font-style: normal;
  font-weight: 300; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "roboto";
    src: url("/assets/fonts/roboto-light/roboto-light.svg#roboto-light") format("svg"); } }

@font-face {
  font-family: "roboto";
  src: url("/assets/fonts/roboto-regular/roboto-regular.eot");
  src: url("/assets/fonts/roboto-regular/roboto-regular.eot") format("embedded-opentype"), url("/assets/fonts/roboto-regular/roboto-regular.woff2") format("woff2"), url("/assets/fonts/roboto-regular/roboto-regular.woff") format("woff"), url("/assets/fonts/roboto-regular/roboto-regular.ttf") format("truetype"), url("/assets/fonts/roboto-regular/roboto-regular.svg#roboto-regular") format("svg");
  font-style: normal;
  font-weight: 400; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "roboto";
    src: url("/assets/fonts/roboto-regular/roboto-regular.svg#roboto-regular") format("svg"); } }

@font-face {
  font-family: "roboto";
  src: url("/assets/fonts/roboto-medium/roboto-medium.eot");
  src: url("/assets/fonts/roboto-medium/roboto-medium.eot") format("embedded-opentype"), url("/assets/fonts/roboto-medium/roboto-medium.woff2") format("woff2"), url("/assets/fonts/roboto-medium/roboto-medium.woff") format("woff"), url("/assets/fonts/roboto-medium/roboto-medium.ttf") format("truetype"), url("/assets/fonts/roboto-medium/roboto-medium.svg#roboto-medium") format("svg");
  font-style: normal;
  font-weight: 500; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "roboto";
    src: url("/assets/fonts/roboto-medium/roboto-medium.svg#roboto-medium") format("svg"); } }

@font-face {
  font-family: "roboto";
  src: url("/assets/fonts/roboto-bold/roboto-bold.eot");
  src: url("/assets/fonts/roboto-bold/roboto-bold.eot") format("embedded-opentype"), url("/assets/fonts/roboto-bold/roboto-bold.woff2") format("woff2"), url("/assets/fonts/roboto-bold/roboto-bold.woff") format("woff"), url("/assets/fonts/roboto-bold/roboto-bold.ttf") format("truetype"), url("/assets/fonts/roboto-bold/roboto-bold.svg#roboto-bold") format("svg");
  font-style: normal;
  font-weight: 700; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "roboto";
    src: url("/assets/fonts/roboto-bold/roboto-bold.svg#roboto-bold") format("svg"); } }

@font-face {
  font-family: "iconfont";
  src: url("/assets/fonts/iconfont/iconfont.eot");
  src: url("/assets/fonts/iconfont/iconfont.eot") format("embedded-opentype"), url("/assets/fonts/iconfont/iconfont.woff2") format("woff2"), url("/assets/fonts/iconfont/iconfont.woff") format("woff"), url("/assets/fonts/iconfont/iconfont.ttf") format("truetype"), url("/assets/fonts/iconfont/iconfont.svg#iconfont") format("svg");
  font-style: normal;
  font-weight: 400; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "iconfont";
    src: url("/assets/fonts/iconfont/iconfont.svg#iconfont") format("svg"); } }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html, body {
  background: #f1f1f1; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.App_normal__4DIXw {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden; }

.Icon_if__d9IVT {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.Icon_upload-cloud__2heq7:before {
  content: "\e93d"; }

.Icon_download-cloud__Ifxbh:before {
  content: "\e93f"; }

.Icon_placeholder__3R93Y:before {
  content: "\e93e"; }

.Icon_chevron-down__1PVO7:before {
  content: "\e940"; }

.Icon_chevron-up__3XdDf:before {
  content: "\e941"; }

.Icon_chevron-left__dduYb:before {
  content: "\e93b"; }

.Icon_chevron-right__1tz4u:before {
  content: "\e93c"; }

.Icon_calendars__2BJfS:before {
  content: "\e93a"; }

.Icon_arrow-shift-left__3nQq1:before {
  content: "\e938"; }

.Icon_arrow-shift-right__2MjPm:before {
  content: "\e939"; }

.Icon_conversation__1pVo2:before {
  content: "\e937"; }

.Icon_excel__3tAtM:before {
  content: "\e900"; }

.Icon_pdf__3B_Oz:before {
  content: "\e901"; }

.Icon_add__19_IG:before {
  content: "\e902"; }

.Icon_arrow-down__2KibJ:before {
  content: "\e903"; }

.Icon_arrow-drop-down__jP9iC:before {
  content: "\e904"; }

.Icon_arrow-drop-left__3iMYZ:before {
  content: "\e905"; }

.Icon_arrow-drop-right__21Rj_:before {
  content: "\e906"; }

.Icon_arrow-drop-up__3LFmt:before {
  content: "\e907"; }

.Icon_arrow-long-right__1BKii:before {
  content: "\e908"; }

.Icon_arrow-up__MW3Dq:before {
  content: "\e909"; }

.Icon_bell___Pi-G:before {
  content: "\e90a"; }

.Icon_chart-up-month__SdU5-:before {
  content: "\e90b"; }

.Icon_chart-up-year__3ADpR:before {
  content: "\e90c"; }

.Icon_chat__xUTR5:before {
  content: "\e90d"; }

.Icon_check__2UTTv:before {
  content: "\e90e"; }

.Icon_checkbox__2gsJ-:before {
  content: "\e90f"; }

.Icon_checkbox-blank__1z-WY:before {
  content: "\e910"; }

.Icon_check-circle__2DUxa:before {
  content: "\e911"; }

.Icon_checklist__rjDA7:before {
  content: "\e912"; }

.Icon_clicker__34SIy:before {
  content: "\e913"; }

.Icon_close__1bT3Y:before {
  content: "\e914"; }

.Icon_close-big__1twZt:before {
  content: "\e915"; }

.Icon_cog__1ibT-:before {
  content: "\e916"; }

.Icon_comments__1iBQB:before {
  content: "\e917"; }

.Icon_create__1jWHE:before {
  content: "\e918"; }

.Icon_dashboard__2RBYL:before {
  content: "\e919"; }

.Icon_delete__2wKlN:before {
  content: "\e91a"; }

.Icon_description__CymI1:before {
  content: "\e91b"; }

.Icon_drag__2kTD9:before {
  content: "\e91c"; }

.Icon_email__1vFBL:before {
  content: "\e91d"; }

.Icon_equalizer__M4dgc:before {
  content: "\e91e"; }

.Icon_filter__1zc4j:before {
  content: "\e91f"; }

.Icon_funnel__3Yu6o:before {
  content: "\e920"; }

.Icon_hand-click__2Cu4L:before {
  content: "\e921"; }

.Icon_info__31y8g:before {
  content: "\e922"; }

.Icon_library__1HyGt:before {
  content: "\e923"; }

.Icon_like__3BkBa:before {
  content: "\e924"; }

.Icon_map__1xi8P:before {
  content: "\e925"; }

.Icon_menu__1VGUw:before {
  content: "\e926"; }

.Icon_more-vert__2XKL3:before {
  content: "\e927"; }

.Icon_notes__lNfo2:before {
  content: "\e928"; }

.Icon_notifications__BYeFj:before {
  content: "\e929"; }

.Icon_refresh__1wNLR:before {
  content: "\e92a"; }

.Icon_rocket__27qK-:before {
  content: "\e92b"; }

.Icon_search__NgQ3q:before {
  content: "\e92c"; }

.Icon_security__2h7vN:before {
  content: "\e92d"; }

.Icon_share__1FEX4:before {
  content: "\e92e"; }

.Icon_star__142mM:before {
  content: "\e92f"; }

.Icon_star-empty__3_lDF:before {
  content: "\e930"; }

.Icon_star-half__2_wgW:before {
  content: "\e931"; }

.Icon_trophy__3ywS6:before {
  content: "\e932"; }

.Icon_tune__8LAlf:before {
  content: "\e933"; }

.Icon_upload__liXwu:before {
  content: "\e934"; }

.Icon_warning__CeJTo:before {
  content: "\e935"; }

.Icon_widgets__1GEqK:before {
  content: "\e936"; }

.Checkbox_normal__1A61m {
  width: 40px;
  height: 20px;
  position: relative;
  background: #c7cbd3;
  border-radius: 50px;
  -webkit-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
  cursor: pointer; }

.Checkbox_active__2iPMZ {
  background: #5376f7; }

.Checkbox_circle__3FQEg {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 2px;
  top: 2px;
  background: #fff;
  border-radius: 50%;
  -webkit-transition: left 0.2s ease-out;
  transition: left 0.2s ease-out; }
  .Checkbox_active__2iPMZ .Checkbox_circle__3FQEg {
    left: 22px; }

.Cards_normal__1g1pg {
  min-height: 100%;
  display: flex;
  align-items: stretch;
  background: #eaeaf2; }

.Cards_table__1MD-f {
  flex-shrink: 0; }

.Cards_cards__qJ-bH {
  position: relative;
  width: 100%;
  background-color: #f4f4f4;
  box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1); }

.Cards_resize__1nUMn {
  color: #3b3a53;
  font-size: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  left: -48px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  background: none;
  cursor: -webkit-grab;
  cursor: grab;
  opacity: 0.7; }
  .Cards_resize__1nUMn:active {
    cursor: -webkit-grabbing;
    cursor: grabbing; }




.Analytics_normal__11d-S {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #eaeaf2;
  overflow: hidden; }

.Header_normal__3RpxR {
  position: relative; }

.Header_container__2LjdP {
  width: 100%;
  flex-shrink: 0;
  padding-bottom: 12px;
  overflow: hidden; }

.Header_draggable__2mfL4 {
  cursor: -webkit-grab;
  cursor: grab; }
  .Header_draggable__2mfL4:active {
    cursor: -webkit-grabbing;
    cursor: grabbing; }

.Header_tape__1Q9NI {
  display: flex;
  align-items: stretch;
  position: relative;
  z-index: 12; }

.Header_date__1IVwX {
  margin-right: 16px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 50px;
  background-color: #f4f4f4;
  box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1);
  pointer-events: none; }

.Header_drag__oZTRi {
  color: #3b3a53;
  font-size: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  right: -48px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  background: none;
  cursor: -webkit-grab;
  cursor: grab;
  opacity: 0;
  -webkit-transition: 0.15s ease;
  transition: 0.15s ease;
  pointer-events: none; }
  .Header_tape__1Q9NI:hover .Header_drag__oZTRi {
    opacity: 0.7; }

.DateRange_normal__3UW-A {
  display: flex;
  align-items: center; }

.DateRange_current__19yD4 {
  margin-right: 25px;
  position: relative;
  pointer-events: auto; }
  .DateRange_currentButton__1TrzU {
    color: #435cc8;
    font-size: 15px;
    font-family: "roboto", sans-serif;
    font-weight: 500;
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 18px;
    background: #fff;
    border: solid 1px #435cc8;
    border-radius: 5px;
    cursor: pointer; }
  .DateRange_currentIcon__1Kymj {
    font-size: 18px;
    position: relative;
    top: -2px;
    pointer-events: none; }
  .DateRange_currentDate__3YHdX {
    margin-left: 15px;
    pointer-events: none; }

.DateRange_list__onfLj {
  display: flex;
  align-items: center;
  border: solid 1px #dadada;
  border-radius: 5px;
  overflow: hidden; }

.DateRange_item__2mOut {
  border-right: solid 1px #dadada;
  pointer-events: auto; }
  .DateRange_item__2mOut:last-child {
    border-right: none; }

.DateDropdown_dropdown__1ml2l {
  position: absolute;
  left: 50px;
  bottom: -5px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  z-index: 20;
  background: #fff;
  box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  opacity: 0;
  cursor: default;
  -webkit-transition: 0.15s ease;
  transition: 0.15s ease; }

.DateDropdown_active__3RsYI {
  bottom: -10px;
  pointer-events: auto;
  opacity: 1; }

.DateRangePicker {
  display: flex;
  margin-bottom: 10px;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.DateRangePicker__PaginationArrow {
  border: 0;
  cursor: pointer;
  display: block;
  height: 35px;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
  z-index: 1; }

.DateRangePicker__PaginationArrow--previous {
  left: 20px; }

.DateRangePicker__PaginationArrow--next {
  right: 20px; }

.DateRangePicker__PaginationArrow:hover {
  background-color: #ccc; }

.DateRangePicker__PaginationArrowIcon {
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  height: 0;
  position: absolute;
  top: 10px;
  width: 0; }

.DateRangePicker__PaginationArrowIcon--is-disabled {
  opacity: .25; }

.DateRangePicker__PaginationArrowIcon--previous {
  border-left: 8px solid transparent;
  border-right: 8px solid #aaa;
  right: 11px; }

.DateRangePicker__PaginationArrowIcon--next {
  border-left: 8px solid #aaa;
  border-right: 8px solid transparent;
  left: 11px; }

.DateRangePicker__Month {
  color: #333;
  display: inline-block;
  margin: 0 20px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 275px; }

.DateRangePicker__MonthHeader {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  height: 35px;
  line-height: 35px;
  position: relative;
  text-align: center; }

.DateRangePicker__MonthHeaderLabel {
  display: inline-block;
  position: relative; }

.DateRangePicker__MonthHeaderSelect {
  background: #e4e4e4;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5; }

.DateRangePicker__MonthDates {
  border-bottom: 1px solid #f4f5f6;
  border-collapse: separate;
  border-spacing: 0 1px;
  margin: 0;
  width: 100%; }

.DateRangePicker__WeekdayHeading, .DateRangePicker__Date {
  font-size: 12px;
  line-height: 1;
  padding: 10px 0;
  text-align: center;
  width: 14.285714285714286%; }

.DateRangePicker__WeekdayHeading {
  border-bottom: 1px solid #f4f5f6;
  color: #000;
  font-weight: bold; }

.DateRangePicker__WeekdayHeading abbr[title] {
  border-bottom-width: 0;
  color: #000;
  cursor: pointer;
  font-size: inherit;
  text-decoration: none; }

.DateRangePicker__Date {
  border: 0 solid #f4f5f6;
  border-right-width: 1px;
  cursor: pointer;
  overflow: hidden;
  position: relative; }

.DateRangePicker__Date:first-child {
  border-left-width: 1px; }

.DateRangePicker__Date--weekend {
  background-color: #f6f7f9; }

.DateRangePicker__Date--otherMonth {
  opacity: .25; }

.DateRangePicker__Date--is-disabled {
  color: #cdcdd1;
  cursor: default; }

.DateRangePicker__Date--is-selected {
  color: #fff; }

.DateRangePicker__Date--is-highlighted {
  color: #333; }

.DateRangePicker__CalendarDatePeriod {
  bottom: 0;
  position: absolute;
  top: 0; }

.DateRangePicker__CalendarDatePeriod--am {
  left: 0;
  right: 50%; }

.DateRangePicker__CalendarDatePeriod--pm {
  left: 50%;
  right: 0; }

.DateRangePicker__CalendarSelection {
  background-color: #435cc8;
  border: 1px solid #435cc8;
  bottom: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 5px; }

.DateRangePicker__CalendarSelection--inOtherMonth {
  opacity: .5; }

.DateRangePicker__CalendarSelection--start {
  border-bottom-left-radius: 5px;
  border-right-width: 0;
  border-top-left-radius: 5px;
  left: 5px; }

.DateRangePicker__CalendarSelection--end {
  border-bottom-right-radius: 5px;
  border-left-width: 0;
  border-top-right-radius: 5px;
  right: 5px; }

.DateRangePicker__CalendarSelection--segment {
  border-left-width: 0;
  border-right-width: 0; }

.DateRangePicker__CalendarSelection--single {
  border-radius: 5px;
  left: 5px;
  right: 5px; }

.DateRangePicker__CalendarSelection--is-pending {
  background-color: rgba(67, 92, 200, 0.75);
  border-width: 0; }

.DateRangePicker__CalendarHighlight {
  background-color: rgba(255, 255, 255, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.25);
  bottom: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 5px; }

.DateRangePicker__CalendarHighlight--inOtherMonth {
  opacity: .5; }

.DateRangePicker__CalendarHighlight--start {
  border-bottom-left-radius: 5px;
  border-right-width: 0;
  border-top-left-radius: 5px;
  left: 5px; }

.DateRangePicker__CalendarHighlight--end {
  border-bottom-right-radius: 5px;
  border-left-width: 0;
  border-top-right-radius: 5px;
  right: 5px; }

.DateRangePicker__CalendarHighlight--segment {
  border-left-width: 0;
  border-right-width: 0; }

.DateRangePicker__CalendarHighlight--single {
  background-color: #fff;
  border: 1px solid #435cc8;
  border-radius: 5px;
  left: 5px;
  right: 5px; }

.DateRangePicker__HalfDateStates {
  bottom: -50px;
  left: -50px;
  position: absolute;
  right: -50px;
  top: -50px;
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg); }

.DateRangePicker__FullDateStates {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.DateRangePicker__DateLabel {
  display: block;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1; }

.PickRange_normal__xiO7U {
  color: #3a3952;
  font-size: 15px;
  font-family: "roboto", sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 42px;
  position: relative;
  background: #fff;
  cursor: pointer; }

.PickRange_active__GFquL {
  color: #fff; }
  .PickRange_active__GFquL:after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    left: -1px;
    top: -1px;
    z-index: 1;
    background: #435cc8; }

.PickRange_title__1kgIj {
  position: relative;
  z-index: 2; }

.TableParams_normal__3p_ti {
  display: flex;
  align-items: center;
  padding: 15px 40px;
  flex-shrink: 0;
  background-color: #f4f4f4;
  box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1);
  pointer-events: none; }

.TableParam_normal__170qw {
  margin-right: 25px;
  display: flex;
  align-items: stretch;
  position: relative;
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 5px;
  cursor: default;
  pointer-events: auto; }
  .TableParam_normal__170qw:Last-child {
    margin-right: 0; }

.TableParam_green__28E6Y {
  border-color: #07bf6f; }

.TableParam_red__1qksK {
  border-color: #ec4e20; }

.TableParam_title__3xHI5 {
  color: #484848;
  font-size: 16px;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  white-space: nowrap;
  padding: 0 25px 0 15px;
  display: flex;
  align-items: center;
  cursor: default; }

.TableParam_inputs__2v3PU {
  min-width: 80px;
  display: flex;
  align-items: baseline;
  padding: 0 15px;
  background: #ebebed; }
  .TableParam_green__28E6Y .TableParam_inputs__2v3PU {
    background: #07bf6f; }
  .TableParam_red__1qksK .TableParam_inputs__2v3PU {
    background: #ec4e20; }

.TableParam_input__PT1-O {
  color: #6d6d6d;
  font-size: 16px;
  font-family: "roboto", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 50px;
  min-width: 50px;
  height: 42px;
  margin-right: 3px;
  background: none; }
  .TableParam_green__28E6Y .TableParam_input__PT1-O, .TableParam_red__1qksK .TableParam_input__PT1-O {
    color: #fff; }
  .TableParam_input__PT1-O::-webkit-inner-spin-button, .TableParam_input__PT1-O::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.TableParam_dimension__27agD {
  color: #b6b6b6;
  font-size: 11px;
  font-family: "roboto", sans-serif;
  font-weight: 500; }
  .TableParam_green__28E6Y .TableParam_dimension__27agD, .TableParam_red__1qksK .TableParam_dimension__27agD {
    color: #fff; }

.TableWrap_normal__1jwmy {
  height: 100%;
  padding: 25px 50px;
  display: flex;
  flex-direction: column;
  background: #fff;
  overflow: hidden; }

.TableSearch_normal__3R7rB {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px; }
  @media (max-width: 1279px) {
    .TableSearch_normal__3R7rB {
      justify-content: space-between; } }

.TableSearch_title__3pDIk {
  color: #3a3952;
  font-size: 20px;
  font-family: "roboto", sans-serif;
  font-weight: 500;
  width: 100%;
  margin-bottom: 10px; }

.TableSearch_desc__23e0x {
  color: #3a3952;
  font-size: 16px;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-right: 325px; }
  @media (max-width: 1499px) {
    .TableSearch_desc__23e0x {
      margin-right: 150px; } }
  @media (max-width: 1279px) {
    .TableSearch_desc__23e0x {
      margin-right: 15px; } }
  .TableSearch_descIcon__21CF9 {
    font-size: 22px;
    margin-right: 6px; }
  .TableSearch_descText__2stzY {
    opacity: 0.8; }
  .TableSearch_descButton__3X6ZK {
    color: #3a3952;
    font-size: 16px;
    font-family: "roboto", sans-serif;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    background: none;
    cursor: pointer; }
    .TableSearch_descButton__3X6ZK:hover {
      color: #435cc8; }
    .TableSearch_descButtonIcon__1Adfz {
      margin-right: 7px; }

.TableSearch_form__lf7wB {
  display: flex;
  align-items: center;
  padding: 0 16px;
  position: relative; }
  .TableSearch_form__lf7wB:before {
    content: '';
    display: block;
    width: 100%;
    height: 44px;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border: solid 1px #dadada;
    border-radius: 50px;
    pointer-events: none; }
  .TableSearch_formButton__3_YK1 {
    color: #878793;
    font-size: 20px;
    margin-right: 20px;
    display: flex;
    background: none;
    cursor: pointer; }
  .TableSearch_formInput__tv1n7 {
    color: #3a3952;
    font-size: 16px;
    font-family: "roboto", sans-serif;
    font-weight: 400;
    width: 405px; }
    .TableSearch_formInput__tv1n7::-webkit-placeholder, .TableSearch_formInput__tv1n7::-moz-placeholder, .TableSearch_formInput__tv1n7:-moz-placeholder, .TableSearch_formInput__tv1n7:-moz-placeholder {
      color: #878793; }

.Table_scrollable__21F5n {
  width: 100%;
  height: 100%;
  overflow: auto; }

.Table_table__3cSc5 {
  width: 100%;
  border-collapse: separate; }

.THead_cell__6bit8 {
  color: #3a3952;
  font-size: 11px;
  font-family: "roboto", sans-serif;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  background: #f8f8f8;
  border-top: solid 1px #ebebed;
  border-right: solid 1px #ebebed; }

.THead_firstCell__2aOHv {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 5;
  background: #fff;
  border-top: none; }

.THead_button__14u_J {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  text-align: left;
  padding: 5px 10px;
  width: 100%;
  height: 100%;
  background: none;
  cursor: pointer; }

.TRow_normal__1x-C2 {
  background: #fff; }
  .TRow_normal__1x-C2:nth-child(even) {
    background: #f8f8f8; }
    .TRow_normal__1x-C2:nth-child(even) td {
      background: #f8f8f8; }
  .TRow_normal__1x-C2:last-child td {
    border-bottom: solid 1px #ebebed; }
  .TRow_normal__1x-C2 td {
    background: #fff; }

.CellName_normal__VGl9g {
  color: #3a3952;
  font-size: 15px;
  font-family: "roboto", sans-serif;
  line-height: 16px;
  font-weight: 400;
  white-space: nowrap;
  padding: 5px 10px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 5;
  border-bottom: solid 1px #ebebed; }
  .CellName_normal__VGl9g:before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #ebebeb; }

.CellName_toggle__2EGtE {
  display: inline;
  width: 16px;
  height: 16px;
  position: relative;
  margin-right: 10px;
  background: #f0f0f7;
  border-radius: 20px;
  cursor: pointer; }
  .CellName_toggle__2EGtE:before, .CellName_toggle__2EGtE:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: #757487; }
  .CellName_toggle__2EGtE:before {
    width: 2px;
    height: 8px; }
  .CellName_toggle__2EGtE:after {
    width: 8px;
    height: 2px; }

.CellName_active__2VQPM { }
  .CellName_active__2VQPM:before {
    content: none; }

.CellName_loading__1HeLn {
  background: #f0f0f7 url("/assets/img/row-loading.svg") center center no-repeat; }
  .CellName_loading__1HeLn:before, .CellName_loading__1HeLn:after {
    content: none; }

.CellName_emptySpan__1r2Cm {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px; }

.CellData_normal__3AK7p {
  color: #3a3952;
  font-size: 15px;
  font-family: "roboto", sans-serif;
  line-height: 16px;
  font-weight: 400;
  white-space: nowrap;
  position: relative;
  border-left: solid 1px #ebebed;
  border-bottom: solid 1px #ebebed; }
  .CellData_normal__3AK7p:nth-child(2) {
    border-left: none; }
  .CellData_normal__3AK7p:last-child {
    border-right: solid 1px #ebebed; }

.CellData_button__oIpLK {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  text-align: left;
  height: 100%;
  width: 100%;
  padding: 5px 10px;
  background: none; }

.CellData_dimension__3EZDc {
  color: #cccccc;
  font-size: 10px;
  font-family: "roboto", sans-serif; }

.CellAdj_normal__17tIB {
  color: #3a3952;
  font-size: 15px;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  text-align: center;
  background: #fff !important;
  border-left: solid 1px #ebebed; }

.CellAdj_dimension__3jqRZ {
  color: #cccccc;
  font-size: 10px;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  margin-left: 2px; }

.CellAdj_icon__2ba2O {
  font-size: 15px;
  margin-right: 7px;
  position: relative;
  top: 1px; }

.CellAdj_green__3PppQ {
  color: #07bf6f; }

.CellAdj_red__HxU3F {
  color: #ec4e20; }

.CellHeaderName_normal__3C46k {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 6;
  background: #fff;
  border-bottom: solid 1px #ebebed; }

.CellHeaderName_cell__2SQwv {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 3px 0;
  position: relative;
  top: -2px; }

.CellHeaderName_text__2TbGf {
  color: #3a3952;
  font-size: 14px;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  margin-left: 10px;
  opacity: 0.5; }

.CellHeaderData_normal__20ljU {
  color: #3a3952;
  font-size: 11px;
  font-family: "roboto", sans-serif;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4;
  vertical-align: middle;
  background: #f8f8f8;
  border-top: solid 1px #ebebed;
  border-left: solid 1px #ebebed;
  border-bottom: solid 1px #ebebed; }
  .CellHeaderData_normal__20ljU:last-child {
    border-right: solid 1px #ebebed; }

.CellHeaderData_button__3f5hr {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  text-align: left;
  padding: 5px 20px 5px 10px;
  width: 100%;
  height: 100%;
  position: relative;
  background: none;
  cursor: pointer; }

.CellHeaderData_sorted__35uS3 {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none; }
  .CellHeaderData_sortedIcon__2ZsYp {
    color: #878793;
    font-size: 17px;
    margin-bottom: 2px;
    position: relative;
    top: 1px; }
    .CellHeaderData_sortedIcon__2ZsYp:last-child {
      margin-bottom: 0;
      top: -11px; }

.CellHeaderData_highlight__3uaG1 {
  color: #3a3952; }

.CellHeaderAdj_normal__E03dx {
  color: #fff;
  font-size: 11px;
  font-family: "roboto", sans-serif;
  font-weight: 500;
  line-height: 16px;
  white-space: nowrap;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4;
  padding: 5px 10px;
  vertical-align: middle;
  border-top: solid 1px #ebebed;
  border-left: solid 1px #ebebed;
  border-bottom: solid 1px #ebebed; }
  .CellHeaderAdj_normal__E03dx:last-child {
    border-right: solid 1px #ebebed; }

.CellHeaderAdj_green__13eFL {
  background: #07bf6f; }

.CellHeaderAdj_red__2I_eG {
  background: #ec4e20; }

.CellHeaderAdj_icon__wK-Xb {
  font-size: 14px;
  margin-right: 7px;
  position: relative;
  top: 1px; }

.Pagination_normal__20wl- {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.Pagination_onPage__1ZDkg {
  display: flex;
  align-items: center; }

.Pagination_toPage__AY66a {
  display: flex;
  align-items: center; }

.Pagination_list__Vtfls {
  display: flex;
  align-items: center;
  margin-right: 28px; }

.Pagination_li__1MsaB {
  margin-right: 5px; }
  .Pagination_li__1MsaB:last-child {
    margin-right: 0; }

.Pagination_button__2DXwI {
  color: #3a3952;
  font-size: 14px;
  font-family: "roboto", sans-serif;
  line-height: 20px;
  width: 32px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: solid 1px #dadada;
  cursor: pointer; }
  .Pagination_button__2DXwI:hover {
    border-color: #435cc8; }

.Pagination_active__235Tk {
  color: #fff;
  background: #435cc8;
  border-color: #435cc8; }

.Pagination_setPage__3K-xk {
  display: flex;
  align-items: center; }

.Pagination_span__2bmzu {
  color: #3a3952;
  font-size: 14px;
  font-family: "roboto", sans-serif;
  line-height: 14px;
  margin-right: 10px;
  display: inline-block; }

.Pagination_select__2Ui-e {
  height: 35px;
  padding: 0 35px 0 15px;
  border: solid 1px #dadada;
  border-radius: 5px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer; }
  .Pagination_selectWrap__2FH73 {
    position: relative; }
  .Pagination_selectArrow__24DFv {
    color: #3a3952;
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 7px;
    pointer-events: none; }

.Pagination_pagIcon__10Yfm {
  font-size: 18px;
  line-height: inherit; }

@-webkit-keyframes Columns_cogRotate__26FLx {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes Columns_cogRotate__26FLx {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.Columns_normal__4l7QQ {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 0 0 20px; }

.Columns_opened__n6ss9 { }

.Columns_open__1FCN9 {
  color: #3a3952;
  font-size: 14px;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 15;
  background: none;
  cursor: pointer; }
  .Columns_openIcon__2fgOM {
    margin-right: 10px;
    pointer-events: none; }
    .Columns_opened__n6ss9 .Columns_openIcon__2fgOM {
      -webkit-animation: Columns_cogRotate__26FLx 0.4s ease 1;
              animation: Columns_cogRotate__26FLx 0.4s ease 1; }
  .Columns_openText__3f2uB {
    color: #bdbdc3;
    position: relative;
    pointer-events: none; }
    .Columns_open__1FCN9:hover .Columns_openText__3f2uB {
      color: #3a3952; }
    .Columns_openText__3f2uB:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #3a3952; }

.Columns_close__2U4Xp {
  color: #ed1e26;
  font-size: 16px;
  position: absolute;
  left: 15px;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: none;
  cursor: pointer; }
  .Columns_closeIcon__3wKSw {
    font-weight: 700; }

.Columns_columns__1svi7 {
  display: block;
  padding: 60px 25px 25px 60px;
  position: absolute;
  right: -25px;
  top: -30px;
  z-index: 14;
  background: #fff;
  box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1);
  opacity: 0;
  -webkit-transition: top 0.15s ease, opacity 0.15s ease;
  transition: top 0.15s ease, opacity 0.15s ease;
  pointer-events: none; }
  .Columns_opened__n6ss9 .Columns_columns__1svi7 {
    top: -20px;
    opacity: 1;
    pointer-events: auto; }

.ColumnsGroup_normal__snNsc {
  display: flex;
  align-items: center;
  margin-bottom: 7px; }
  .ColumnsGroup_normal__snNsc:last-child {
    margin-bottom: 0; }

.ColumnsGroup_title__1imCy {
  color: #3a3952;
  font-size: 16px;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  margin-right: 25px; }

.ColumnsCheckbox_normal__noohC {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer; }
  .ColumnsCheckbox_normal__noohC:last-child {
    margin-right: 0; }

.ColumnsCheckbox_active__kIU9v { }

.ColumnsCheckbox_input__30Zrv {
  display: none; }

.ColumnsCheckbox_icon__5Cni8 {
  color: #878793;
  font-size: 20px;
  margin-right: 10px; }
  .ColumnsCheckbox_active__kIU9v .ColumnsCheckbox_icon__5Cni8 {
    color: #357ffa; }

.ColumnsCheckbox_label__2fTSS {
  color: #3a3952;
  font-size: 16px;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  opacity: 0.42; }
  .ColumnsCheckbox_active__kIU9v .ColumnsCheckbox_label__2fTSS {
    opacity: 1; }
  .ColumnsCheckbox_labelIcon__1J72X {
    font-size: 14px;
    margin-right: 5px; }

.ColumnsCheckbox_green__cmm3O {
  color: #07bf6f;
  opacity: 1; }

.ColumnsCheckbox_red__3coV1 {
  color: #ec4e20;
  opacity: 1; }

@-webkit-keyframes Hierarchy_refreshRotate__2VYdf {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes Hierarchy_refreshRotate__2VYdf {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.Hierarchy_normal__wLKG3 {
  position: relative;
  padding-top: 12px;
  z-index: 10; }

.Hierarchy_opened__1TAtH { }

.Hierarchy_dropdown__A51Jk {
  padding: 20px 25px 65px 17px;
  position: absolute;
  left: 0;
  bottom: -30px;
  left: -25px;
  opacity: 0;
  -webkit-transition: bottom 0.15s ease, opacity 0.15s ease;
  transition: bottom 0.15s ease, opacity 0.15s ease;
  pointer-events: none; }
  .Hierarchy_dropdown__A51Jk:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    background: #fff;
    box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1);
    border: solid 1px #ebebed;
    pointer-events: none; }
  .Hierarchy_opened__1TAtH .Hierarchy_dropdown__A51Jk {
    bottom: -20px;
    opacity: 1;
    pointer-events: auto; }

.Hierarchy_button__2pO9L {
  display: flex;
  position: relative;
  z-index: 11;
  background: none;
  cursor: pointer; }
  .Hierarchy_buttonIcon__1yGez {
    font-size: 16px;
    margin-right: 12px;
    pointer-events: none; }
    .Hierarchy_opened__1TAtH .Hierarchy_buttonIcon__1yGez {
      -webkit-animation: Hierarchy_refreshRotate__2VYdf 0.4s ease 1;
              animation: Hierarchy_refreshRotate__2VYdf 0.4s ease 1; }
  .Hierarchy_buttonLabel__1myUL {
    color: #bdbdc3;
    font-size: 14px;
    font-family: "roboto", sans-serif;
    font-weight: 400;
    position: relative;
    pointer-events: none; }
    .Hierarchy_button__2pO9L:hover .Hierarchy_buttonLabel__1myUL {
      color: #3a3952; }
    .Hierarchy_buttonLabel__1myUL:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #3a3952; }

.Hierarchy_close__1VdSh {
  color: #ed1e26;
  font-size: 16px;
  position: absolute;
  right: 10px;
  bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  z-index: 12;
  background: none;
  cursor: pointer; }
  .Hierarchy_closeIcon__1lwkh {
    font-weight: 700; }

.Hierarchy_list__2fKEj {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: -5px;
  position: relative;
  z-index: 13; }

.HierarchyItem_li__4_l5u {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  padding: 3px;
  background: #fff; }

.HierarchyItem_title__2o87k {
  color: #3a3952;
  font-size: 16px;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  margin-right: 10px; }

.HierarchyItem_icon__DbEu7 {
  font-size: 20px;
  margin-right: 4px;
  position: relative;
  top: -1px; }

.Footer_normal__dqBh3 {
  padding-top: 10px;
  flex-shrink: 0; }

.Filters_normal__2sH8h {
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
  height: 100vh;
  z-index: 50;
  -webkit-transform: translateX(105%);
          transform: translateX(105%);
  background: #414159; }

.Filters_opened__3Hig4 {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.Filters_header__323dL {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  background: #3a3a50; }
  .Filters_headerItem__3VANg {
    width: 50%;
    position: relative; }
    .Filters_headerItem__3VANg:after {
      content: '';
      display: block;
      width: 2px;
      height: 43px;
      position: absolute;
      right: -1px;
      top: 18px;
      z-index: 5;
      background: #fff;
      opacity: 0.1;
      pointer-events: none; }
  .Filters_headerButton__2hZtv {
    color: #fff;
    padding: 18px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: none;
    cursor: pointer; }
    .Filters_headerButton__2hZtv:hover {
      background: #414159; }
  .Filters_headerIcon__37Hea {
    font-size: 25px;
    margin-bottom: 5px; }
  .Filters_headerText__1vRwu {
    color: #fff;
    font-size: 13px;
    font-family: "roboto", sans-serif;
    font-weight: 500; }

.Filters_reset__U066H {
  color: #fff;
  font-size: 12px;
  font-family: "roboto", sans-serif;
  font-weight: 300;
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-bottom: 30px;
  background: none;
  opacity: 0.24;
  cursor: pointer; }
  .Filters_reset__U066H:hover {
    opacity: 1; }
  .Filters_resetIcon__2qIP6 {
    font-size: 16px;
    margin-right: 11px; }

.Filters_close__2KDIo {
  color: #fff;
  font-size: 13px;
  font-family: "roboto", sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
  position: absolute;
  right: 25px;
  bottom: 25px;
  background: none;
  cursor: pointer; }
  .Filters_closeIcon__3TfVm {
    font-size: 18px;
    margin-left: 10px; }

.FiltersGroup_normal__3abmb {
  position: relative; }

.FiltersGroup_opened__fFUEA {
  background: #3a3a50; }

.FiltersGroup_button__1-Vgp {
  color: #ffffff;
  font-size: 14px;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  padding: 23px 40px 23px 30px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  background: none;
  cursor: pointer; }
  .FiltersGroup_button__1-Vgp:hover {
    background: #393952; }
  .FiltersGroup_opened__fFUEA .FiltersGroup_button__1-Vgp {
    opacity: 0.3; }
  .FiltersGroup_buttonIcon__2Fauu {
    color: #fff;
    font-size: 27px;
    margin-right: 13px; }
  .FiltersGroup_buttonChevron__2T3L4 {
    font-size: 20px;
    position: absolute;
    right: 18px;
    top: 26px; }

.Filter_normal__1SBLr {
  padding: 5px 30px 15px; }

.Filter_input__yyGKj {
  color: #fff;
  font-size: 14px;
  font-family: "roboto", sans-serif;
  font-weight: 300;
  width: 100%;
  padding-bottom: 2px;
  margin-bottom: 20px;
  background: none;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2); }
  .Filter_input__yyGKj::-webkit-placeholder, .Filter_input__yyGKj::-moz-placeholder, .Filter_input__yyGKj:-moz-placeholder, .Filter_input__yyGKj:-o-placeholder {
    color: #6e6e80; }

.Filter_values__35nlK {
  max-height: 130px;
  overflow-y: auto; }
  .Filter_values__35nlK::-webkit-scrollbar {
    width: 5px;
    background: #414159; }
  .Filter_values__35nlK::-webkit-scrollbar-thumb {
    width: 5px;
    background: #6c6cb9; }
  .Filter_valuesItem__1Kaf- {
    color: #fff;
    font-size: 14px;
    font-family: "roboto", sans-serif;
    font-weight: 400;
    margin-bottom: 10px; }
    .Filter_valuesItem__1Kaf-:last-child {
      margin-bottom: 0; }

.ActiveFilters_normal__Y9XvQ {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px 12px;
  margin: -7px 0; }

.ActiveFilters_open__yUAM7 {
  color: #3a3952;
  font-size: 24px;
  position: relative;
  left: -10px;
  height: 42px;
  width: 77px;
  padding-right: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #fff;
  border-radius: 0 4px 4px 0; }

.ActiveFilters_filter__2tDMS {
  color: #fff;
  margin: 7px;
  height: 42px;
  min-width: 200px;
  padding: 0 20px 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #414159;
  border: solid 2px #414159;
  border-radius: 50px;
  cursor: pointer; }
  .ActiveFilters_filter__2tDMS:hover {
    color: #414159;
    background: #fff; }
  .ActiveFilters_filterTitle__1vbtP {
    font-size: 14px;
    font-family: "roboto", sans-serif;
    font-weight: 400; }
  .ActiveFilters_filterIcon__sd9st {
    margin-left: 15px; }

.ChangeClient_normal__3jHZG {
  margin-right: 25px;
  height: 42px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  position: relative;
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 5px;
  pointer-events: auto;
  cursor: pointer; }

.Main_normal__qjCV8 {
  height: 100%;
  overflow-y: auto; }

.Input_normal__38z5s {
  width: 100%;
  margin-right: 10px; }
  .Input_normal__38z5s:last-child {
    margin-right: 0; }

.Input_input__1v9NU {
  color: #3a3952;
  font-size: 16px;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  width: 100%;
  height: 44px;
  padding: 0 15px;
  background: #f6f6f6;
  border: solid 1px #dadada;
  border-radius: 5px; }
  .Input_input__1v9NU::-webkit-placeholder, .Input_input__1v9NU::-moz-placeholder, .Input_input__1v9NU:-moz-placeholder, .Input_input__1v9NU:-ms-placeholder {
    opacity: 0.8; }

.FormModule_normal__-W3sr {
  margin-bottom: 30px; }

.Form_normal__2lRRU {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }
  .Form_normal__2lRRU:last-child {
    margin-bottom: 0; }

.Form_header__3j3l9 {
  margin-bottom: 30px; }

.Form_title__ZaTns, .Form_subtitle__kc7zx {
  color: #3a3952;
  font-family: "roboto", sans-serif; }

.Form_title__ZaTns {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px; }
  .Form_title__ZaTns:last-child {
    margin-bottom: 0; }

.Form_subtitle__kc7zx {
  font-size: 16px;
  font-weight: 400;
  opacity: 0.8; }

.Form_form__DpnRM {
  display: flex;
  flex-direction: column; }

.Form_button__2gCNF {
  color: #fff;
  font-size: 16px;
  font-family: "roboto", sans-serif;
  font-weight: 500;
  align-self: center;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #357ffa;
  border: solid 2px #357ffa;
  border-radius: 5px;
  -webkit-transition: color 0.2s ease, background 0.2s ease;
  transition: color 0.2s ease, background 0.2s ease;
  cursor: pointer; }
  .Form_button__2gCNF:hover {
    color: #357ffa;
    background: #fff; }

.Form_disabled__Uve46 {
  opacity: 0.7;
  pointer-events: none; }

.AuthCheckbox_normal__Hg-bR {
  color: #3a3952;
  font-family: "roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  display: inline-flex;
  margin-bottom: 30px;
  cursor: pointer; }
  .AuthCheckbox_normal__Hg-bR:last-child {
    margin-bottom: 0; }

.AuthCheckbox_checkbox__2yMDm {
  display: none; }

.AuthCheckbox_check__2qmjQ {
  margin-right: 10px;
  display: block;
  width: 14px;
  height: 14px;
  position: relative;
  border: solid 2px #000000; }
  .AuthCheckbox_check__2qmjQ:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 2;
    background: #fff;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease; }

.AuthCheckbox_active__3H_1h { }
  .AuthCheckbox_active__3H_1h:after {
    right: -6px;
    top: -2px; }

.AuthCheckbox_icon__1Evwe {
  font-size: 12px; }
  .AuthCheckbox_iconWrap__3jeoK {
    position: absolute;
    left: 1px;
    top: -3px;
    z-index: 5;
    width: 0px;
    overflow: hidden;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease; }
    .AuthCheckbox_active__3H_1h .AuthCheckbox_iconWrap__3jeoK {
      width: 12px; }

.Login_formModuleHeader__2BXQb {
  color: #3a3952;
  font-family: "roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between; }

.Login_forgotPass__3psl4 {
  color: inherit; }
  .Login_forgotPass__3psl4:hover {
    text-decoration: underline; }

.Registration_formModuleInputs__8uC5f {
  display: flex;
  align-items: flex-start; }

.Registration_formModuleHeader__3j1eZ {
  color: #3a3952;
  font-family: "roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between; }

.Registration_agreement__1srK7 {
  color: inherit; }
  .Registration_agreement__1srK7:hover {
    text-decoration: underline; }

@-webkit-keyframes Auth_showAnimation__UjpEq {
  from {
    top: -20px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

@keyframes Auth_showAnimation__UjpEq {
  from {
    top: -20px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

.Auth_normal__S8HB7 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 430px;
  position: relative;
  overflow-y: auto;
  opacity: 0;
  top: -20px;
  -webkit-animation: Auth_showAnimation__UjpEq 0.2s ease 1;
          animation: Auth_showAnimation__UjpEq 0.2s ease 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.Auth_textSpan__3eJMU,
.Auth_change__39QN6 {
  font-size: 16px;
  font-family: "roboto", sans-serif;
  font-weight: 500; }

.Auth_text__1J8mV {
  display: flex;
  justify-content: center; }
  .Auth_textSpan__3eJMU {
    color: #3a3952;
    margin-right: 20px;
    opacity: 0.9; }
    .Auth_textSpan__3eJMU:last-child {
      margin-right: 0; }

.Auth_change__39QN6 {
  color: #357ffa;
  text-decoration: underline; }
  .Auth_change__39QN6:hover {
    text-decoration: none; }

.Authorization_normal__zSxh6 {
  display: flex;
  align-items: stretch;
  height: 100%; }

.Authorization_left__2zQUO {
  width: 50%;
  background: url("/assets/img/background.png") center bottom no-repeat; }

.Authorization_right__kArA4 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff; }

.Authorization_authFooter__3n6gQ {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 30px;
  background: #f6f6f6; }
  .Authorization_authFooterLink__1p__p {
    color: #3a3952;
    font-family: "roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.9; }
    .Authorization_authFooterLink__1p__p:hover {
      text-decoration: underline; }

.Authorization_links__1-XiB {
  display: flex; }
  .Authorization_linksItem__3gbEL {
    margin-right: 25px; }
    .Authorization_linksItem__3gbEL:last-child {
      margin-right: 0; }

.Authorization_copyright__1HdpF {
  color: #3a3952;
  font-family: "roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.9; }

.Start_normal__2XSeR {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 55px;
  background: url("/assets/img/background.png") center bottom no-repeat;
  overflow-y: auto; }

.Start_advice__2A3ZS {
  width: calc(100% - 30px);
  height: 60px;
  max-width: 1580px;
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1);
  overflow: hidden; }
  .Start_advice__2A3ZS:after {
    content: '';
    display: block;
    position: absolute;
    width: 122px;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 10;
    background-image: -webkit-linear-gradient(4deg, rgba(255, 255, 255, 0.50196) 0%, rgba(255, 255, 255, 0.75) 10%, white 100%);
    background-image: linear-gradient(86deg, rgba(255, 255, 255, 0.50196) 0%, rgba(255, 255, 255, 0.75) 10%, white 100%); }
  .Start_adviceText__3h1JW {
    color: #3a3952;
    font-size: 15px;
    font-family: "roboto", sans-serif;
    font-weight: 400;
    white-space: nowrap;
    position: relative;
    z-index: 8; }

.Start_label__2nCDo {
  position: relative;
  flex-shrink: 0;
  margin-right: 30px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 25px; }
  .Start_label__2nCDo:before {
    content: '';
    display: block;
    width: 330px;
    height: 330px;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transform: translate(-50%, -50%) rotate(-60deg);
            transform: translate(-50%, -50%) rotate(-60deg);
    z-index: 2;
    background: #435cc8; }
  .Start_label__2nCDo:after {
    content: '';
    display: block;
    width: 338px;
    height: 338px;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transform: translate(-50%, -50%) rotate(-60deg);
            transform: translate(-50%, -50%) rotate(-60deg);
    z-index: 1;
    background: #6c6cb9; }
  .Start_labelIcon__1FkSY {
    color: #fff;
    font-size: 28px;
    margin-right: 11px;
    position: relative;
    z-index: 3;
    top: -1px; }
  .Start_labelText__V4mXZ {
    color: #fff;
    font-size: 14px;
    font-family: "roboto", sans-serif;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    position: relative;
    z-index: 3;
    top: 1px; }

.NotFound_normal__2aSC1 {
  height: 100%;
  width: 100%;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("/assets/img/background.png") center bottom no-repeat; }

.NotFound_title__3i7k3 {
  color: #393952;
  font-size: 130px;
  font-family: "roboto", sans-serif;
  font-weight: 700;
  margin-bottom: 30px; }

.NotFound_text__3J_Rb,
.NotFound_link__2Fs0C {
  color: #393952;
  font-size: 20px;
  font-family: "roboto", sans-serif;
  font-weight: 500;
  margin-bottom: 15px; }
  .NotFound_text__3J_Rb:last-child,
  .NotFound_link__2Fs0C:last-child {
    margin-bottom: 0; }

.NotFound_link__2Fs0C {
  text-decoration: underline; }
  .NotFound_link__2Fs0C:hover {
    text-decoration: none; }

.Footer_normal__1jjWi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  flex-shrink: 0;
  padding: 0 50px;
  background: #fff;
  border-top: solid 1px #e8e8e8;
  border-bottom: solid 1px #e8e8e8; }

.Footer_copy__3UlL4 {
  width: 15%;
  flex-shrink: 0; }

.Footer_links__2gyvc {
  width: 100%;
  display: flex;
  height: 100%; }
  .Footer_linksLeft__15sJo, .Footer_linksRight__3VPYq {
    display: flex;
    width: 50%;
    height: 100%; }
  .Footer_linksLeft__15sJo {
    justify-content: flex-end;
    padding-right: 70px; }
  .Footer_linksRight__3VPYq {
    justify-content: center;
    width: 100%; }

.Footer_payment__3rIjs {
  width: 15%;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end; }
  .Footer_paymentList__5Icoi {
    display: flex;
    align-items: center; }
  .Footer_paymentItem__2qlK1 {
    margin-right: 10px; }
    .Footer_paymentItem__2qlK1:last-child {
      margin-right: 0; }

.Links_normal__10jO8 {
  display: flex;
  align-items: center;
  height: 100%;
  border-left: solid 1px #e8e8e8;
  border-right: solid 1px #e8e8e8; }

.Links_item__Qso3b {
  height: 100%;
  border-right: solid 1px #e8e8e8; }
  .Links_item__Qso3b:last-child {
    border-right: none; }

.Links_link__2-cba {
  color: #414159;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  position: relative; }
  .Links_link__2-cba:after {
    content: none;
    display: block;
    width: calc(100% + 2px);
    height: 83px;
    position: absolute;
    left: -1px;
    bottom: -1px;
    z-index: 1;
    background: #435cc8;
    border-radius: 4px 4px 0 0; }

.Links_active__2fqaR {
  color: #fff; }
  .Links_active__2fqaR:after {
    content: ''; }

.Links_icon__1Vx-L {
  font-size: 20px;
  margin-bottom: 9px;
  position: relative;
  z-index: 2; }

.Links_title__18TmQ {
  font-size: 13px;
  font-family: "roboto", sans-serif;
  font-weight: 500;
  line-height: 15px;
  position: relative;
  z-index: 2; }

.Header_normal__MBkev {
  position: relative; }

.Header_header__1UgUU {
  display: flex;
  align-items: center;
  height: 80px;
  position: relative;
  z-index: 20;
  background: #fff;
  box-shadow: 0 -3px 3px 5px rgba(0, 0, 0, 0.1); }

.Header_title__3XXek {
  color: #393952;
  font-size: 22px;
  font-family: "roboto", sans-serif;
  font-weight: 700;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center; }
  .Header_titleSpan__1zItq {
    position: relative;
    top: 4px; }

.Header_logo__Onqu1 {
  margin-right: 20px; }
  .Header_logo__Onqu1:last-child {
    margin-right: 0; }

.User_normal__WXtFd {
  flex-shrink: 0;
  min-width: 230px;
  height: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-left: solid 1px #e4e4e4; }

.User_notification__3O_Gv {
  display: flex;
  align-items: center;
  margin-right: 50px; }
  .User_notification__3O_Gv:last-child {
    margin-right: 0; }
  .User_notificationItem__2gt4r {
    margin-right: 30px; }
    .User_notificationItem__2gt4r:last-child {
      margin-right: 0; }
  .User_notificationButton__3FYz2 {
    color: #3a3952;
    font-size: 18px;
    position: relative;
    background: transparent;
    cursor: pointer; }
    .User_notificationButton__3FYz2:hover .User_icon__9Z4b_ {
      opacity: 1; }
    .User_notificationButton__3FYz2:after {
      content: none;
      display: block;
      width: 12px;
      height: 12px;
      position: absolute;
      right: -4px;
      top: -3px;
      background: #ec4e20;
      border: solid 2px #fff;
      border-radius: 10px; }
    .User_notificationButtonNew__3oVoB { }
      .User_notificationButtonNew__3oVoB:after {
        content: ''; }

.User_icon__9Z4b_ {
  opacity: 0.6; }

.User_user__2_Ni- {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #f0f0f7;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer; }

.UserInfo_normal__Wt3J1 {
  width: 450px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 4;
  padding-top: 30px;
  background: #fff;
  box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1); }

.UserInfo_ava__3j70- {
  display: block;
  width: 80px;
  height: 80px;
  position: relative;
  margin-bottom: 23px; }
  .UserInfo_avaFigure__3ND51 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f0f7;
    border-radius: 50%;
    overflow: hidden; }
  .UserInfo_avaEdit__3OVam {
    position: absolute;
    right: -6px;
    top: 1px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50px;
    overflow: hidden;
    cursor: pointer; }
    .UserInfo_avaEdit__3OVam:hover .UserInfo_avaIcon__1m93E {
      opacity: 1; }
  .UserInfo_avaIcon__1m93E {
    color: #3a3952;
    font-size: 20px;
    opacity: 0.6; }

.UserInfo_name__EvD_f {
  display: flex;
  align-items: center;
  margin-bottom: 11px; }
  .UserInfo_nameUser__2vla6 {
    color: #3a3952;
    font-size: 24px;
    font-family: "roboto", sans-serif;
    font-weight: 500; }
  .UserInfo_nameEdit__2W_y5 {
    color: #3a3952;
    font-size: 20px;
    margin-left: 10px;
    display: flex;
    background: none;
    border-bottom: solid 3px #3a3952;
    opacity: 0.42;
    cursor: pointer; }
    .UserInfo_nameEdit__2W_y5:hover {
      opacity: 0.7; }

.UserInfo_email__2uuxc {
  color: #525252;
  font-size: 16px;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  margin-bottom: 40px; }

.UserInfo_pass__3tITy {
  display: flex;
  align-items: center;
  padding-left: 30px;
  position: relative;
  margin-bottom: 30px;
  background: none;
  cursor: pointer; }
  .UserInfo_pass__3tITy:hover .UserInfo_passText__RZExX {
    opacity: 0.7; }
  .UserInfo_pass__3tITy:after {
    content: '';
    display: block;
    width: calc(100% - 30px);
    height: 1px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #3a3952;
    opacity: 0.85; }
  .UserInfo_passIcon__22a8v {
    color: #414159;
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 0; }
  .UserInfo_passText__RZExX {
    color: #3a3952;
    font-size: 16px;
    font-family: "roboto", sans-serif;
    font-weight: 400;
    display: inline-block;
    position: relative;
    opacity: 0.42; }

.UserInfo_stats__3gcvS {
  width: 100%;
  padding: 25px 0;
  display: flex;
  align-items: center;
  background: #fafafa;
  border-top: solid 1px #ebebed; }
  .UserInfo_statsItem__2S68q {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: #ebebed solid 1px; }
    .UserInfo_statsItem__2S68q:last-child {
      border-right: none; }
  .UserInfo_statsValue__3mD2e {
    color: #3a3952;
    font-size: 18px;
    font-family: "roboto", sans-serif;
    font-weight: 700;
    margin-bottom: 12px; }
  .UserInfo_statsLabel__Tz9J6 {
    color: #3a3952;
    font-size: 15px;
    font-family: "roboto", sans-serif;
    font-weight: 400;
    opacity: 0.9; }

.ProjectsRow_normal__12rn7 {
  position: relative;
  z-index: 3;
  border-bottom: solid 1px #d8d8d8; }
  .ProjectsRow_normal__12rn7:last-child {
    border-bottom: none; }

.ProjectsRow_products__1qZDI {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 2;
  background: transparent; }

.ProjectsRow_projectsList__1wCb8 {
  display: flex;
  align-items: center;
  width: 100%; }

.ProjectsRow_projectsItem__25xET {
  flex-shrink: 0;
  position: relative;
  border-right: solid 1px #d8d8d8; }
  .ProjectsRow_products__1qZDI .ProjectsRow_projectsItem__25xET {
    border-right: none; }

.ProjectsRow_projectsButton__1G59A {
  color: #3a3952;
  font-size: 16px;
  font-family: "roboto", sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 45px;
  position: relative;
  background: transparent;
  cursor: pointer; }
  .ProjectsRow_projectsButton__1G59A:before {
    content: none;
    display: block;
    width: 3px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: #435cc8; }
    .ProjectsRow_products__1qZDI .ProjectsRow_projectsButton__1G59A:before {
      content: '';
      width: 1px;
      height: 20px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      background: #d6d6d6; }
  .ProjectsRow_projectsButton__1G59A:after {
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    margin-left: 10px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: relative;
    top: -2px;
    border-right: solid 2px #3a3952;
    border-bottom: solid 2px #3a3952; }
    .ProjectsRow_products__1qZDI .ProjectsRow_projectsButton__1G59A:after {
      content: none; }

.ProjectsRow_active__3_IqM {
  color: #435cc8;
  background: #fff;
  pointer-events: none; }
  .ProjectsRow_active__3_IqM:before {
    content: ''; }
    .ProjectsRow_products__1qZDI .ProjectsRow_active__3_IqM:before {
      height: 100%; }
  .ProjectsRow_products__1qZDI .ProjectsRow_active__3_IqM {
    box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1); }

.Projects_normal__3hApL {
  flex-shrink: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #f0f0f7; }

.Projects_rows__1vWB0 {
  position: relative;
  z-index: 3;
  box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1); }

.Filters_normal__34OC_ {
  padding: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 100%;
  background: #eaeaf2; }

.Filters_filters__3sbqG, .Filters_labels__usbEb {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: -15px; }

.Filters_filters__3sbqG {
  margin-right: 30px; }

.Filters_labels__usbEb {
  flex-shrink: 0; }

.List_normal__124pP {
  margin: 15px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 4.85px 0.15px rgba(0, 0, 0, 0.1);
  overflow: hidden; }

.List_blue__25ryf {
  background: #357ffa; }

.List_title__1ryOk {
  color: #3a3952;
  font-size: 18px;
  font-family: "roboto", sans-serif;
  font-weight: 500;
  line-height: 22px;
  padding: 20px;
  border-bottom: solid 1px #ebebed; }
  .List_blue__25ryf .List_title__1ryOk {
    color: #fff;
    border-bottom: solid 1px #2d6cd5; }

.List_list__3T007 {
  padding: 0 20px; }

.List_save__2Cqvb {
  cursor: pointer; }

.List_add__1zLEB {
  color: #727272;
  font-size: 24px;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  border-top: solid 1px #ebebed;
  cursor: pointer; }
  .List_blue__25ryf .List_add__1zLEB {
    color: #fff;
    background: #2d6cd5;
    border-top-color: #2d6cd5; }
  .List_add__1zLEB:hover {
    color: #fff;
    background: #435cc8; }

.ListItem_normal__3Wjn5 {
  min-width: 310px;
  padding: 25px 0;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #ebebed; }
  .ListItem_normal__3Wjn5:last-child {
    border-bottom: none; }

.ListItem_blue__1FN4E {
  border-bottom: solid 1px #2d6cd5; }

.ListItem_delete__o52GN {
  flex-shrink: 0;
  margin-right: 15px;
  background: none;
  cursor: pointer; }

.ListItem_title__1K-nJ {
  color: #3a3952;
  font-size: 16px;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  width: 100%;
  padding-right: 15px; }
  .ListItem_blue__1FN4E .ListItem_title__1K-nJ {
    color: #fff; }

.ListItem_info__3y2yW {
  flex-shrink: 0;
  display: flex;
  align-items: center; }
  .ListItem_infoItem__11OTa {
    color: #727272;
    font-size: 14px;
    font-family: "roboto", sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-right: 30px; }
    .ListItem_blue__1FN4E .ListItem_infoItem__11OTa {
      color: #fff; }
    .ListItem_infoItem__11OTa:last-child {
      margin-right: 0; }

.ListItem_iconDelete__2EkgB {
  color: #ed1e26;
  font-size: 16px;
  font-weight: 700; }
  .ListItem_blue__1FN4E .ListItem_iconDelete__2EkgB {
    color: #fff; }

.ListItem_iconInfo__pQw2X {
  color: #727272;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
  top: -2px; }
  .ListItem_blue__1FN4E .ListItem_iconInfo__pQw2X {
    color: #fff; }

