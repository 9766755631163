@import 'styles/variables.scss';

.normal {
  color: #3a3952; font-size: 11px; font-family: $r; font-weight: 500; line-height: 16px; text-transform: uppercase; white-space: nowrap;
  position: sticky; top: 0; z-index: 4; vertical-align: middle;
  background: #f8f8f8; border-top: solid 1px #ebebed; border-left: solid 1px #ebebed; border-bottom: solid 1px #ebebed;

  &:last-child {
     border-right: solid 1px #ebebed;
  }
}

.button {
  color: inherit; font-size: inherit; font-family: inherit; line-height: inherit; text-align: left;
  padding: 5px 20px 5px 10px; width: 100%; height: 100%; position: relative;
  background: none;
  cursor: pointer;
}

.sorted {
  display: flex; flex-direction: column; position: absolute; right: 0; top: 0;
  pointer-events: none;

  &Icon {
    color: #878793; font-size: 17px;
    margin-bottom: 2px; position: relative; top: 1px;

    &:last-child {
      margin-bottom: 0; top: -11px;
    }
  }
}

.highlight {
  composes: sortedIcon;
  color: #3a3952;
}
