@import 'styles/variables.scss';

.normal {
  display: flex; align-items: center; height: 100%;
  border-left: solid 1px #e8e8e8; border-right: solid 1px #e8e8e8;
}

.item {
  height: 100%;
  border-right: solid 1px #e8e8e8;

  &:last-child {
    border-right: none;
  }
}

.link {
  color: #414159;
  display: flex; flex-direction: column; align-items: center; justify-content: center; width: 80px; height: 100%; position: relative;

  &:after {
    content: none;
    display: block; width: calc(100% + 2px); height: 83px; position: absolute; left: -1px; bottom: -1px; z-index: 1;
    background: #435cc8; border-radius: 4px 4px 0 0;
  }
}

.active {
  color: #fff;

  &:after {
    content: '';
  }
}

.icon {
  font-size: 20px;
  margin-bottom: 9px; position: relative; z-index: 2;
}

.title {
  font-size: 13px; font-family: $r; font-weight: 500; line-height: 15px;
  position: relative; z-index: 2;
}
