.normal {
  width: 40px; height: 20px; position: relative;
  background: #c7cbd3; border-radius: 50px;
  transition: background 0.2s ease-out;
  cursor: pointer;
}

.active {
  composes: normal;
  background: #5376f7;
}

.circle {
  width: 16px; height: 16px; position: absolute; left: 2px; top: 2px;
  background: #fff; border-radius: 50%;
  transition: left 0.2s ease-out;

  .active & {
    left: 22px;
  }
}
