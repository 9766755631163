@import 'styles/variables.scss';

.normal {
  width: 100%; margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}

.input {
  color: #3a3952; font-size: 16px; font-family: $r; font-weight: 400;
  width: 100%; height: 44px; padding: 0 15px;
  background: #f6f6f6; border: solid 1px #dadada; border-radius: 5px;

  &::-webkit-placeholder,
  &::-moz-placeholder,
  &:-moz-placeholder,
  &:-ms-placeholder {
    opacity: 0.8;
  }
}
